import React from "react";
import "./style.scss";
import {
  btnAppleBlackBg,
  btnGoogleBlackBg,
  prepareExamBackgroundDesktop,
  prepareExamBackgroundMobile,
} from "assestes";

function PrepareExam() {
  return (
    <div className="prepare-exam">
      <div className="desk-back">
        <img
          src={prepareExamBackgroundDesktop}
          alt="prepareExamBackgroundDesktop"
        />
      </div>
      <div className="mobile-back">
        <img
          src={prepareExamBackgroundMobile}
          alt="prepareExamBackgroundMobile"
        />
      </div>
      <div className="details">
        <div className="sub-title">Let’s wait no longer!</div>
        <div className="title">Prepare for Driving Exam!</div>
        <div className="download-buttons">
          <img
            className="btn"
            src={btnGoogleBlackBg}
            alt="btnGoogleBlackBg"
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.rutes",
                "_blank"
              )
            }
          />
          <img
            className="btn"
            src={btnAppleBlackBg}
            alt="btnAppleBlackBg"
            onClick={() =>
              window.open("https://apps.apple.com/us/app/Rutes/", "_blank")
            }
          />
        </div>
      </div>
    </div>
  );
}

export default PrepareExam;
