import {
  btnAppleBlackBg,
  btnGoogleBlackBg,
  headerIntro,
  mobileIntro,
  rightGreenIcon,
} from "assestes";
import React from "react";
import "./style.scss";

function HomeSection() {
  return (
    <div className="home-section-wrapper">
      <div className="home-section" id="home-section">
        <div className="left">
          <div className="sub-title-top">
            Get ready to <span>PASS</span>{" "}
            <img src={rightGreenIcon} alt="rightGreenIcon" />
          </div>
          <div className="title">DRIVING TEST</div>
          <div className="sub-title-bottom">
            All driving routes available before you even have your test! It’s
            like seeing the answers to a maths test before you take it!
          </div>
          <div className="header-download-section">
            <div className="header-download-buttons">
              <img
                className="btn"
                src={btnGoogleBlackBg}
                alt="btnGoogleBlackBg"
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.rutes",
                    "_blank"
                  )
                }
              />
              <img
                className="btn"
                src={btnAppleBlackBg}
                alt="btnAppleBlackBg"
                onClick={() =>
                  window.open("https://apps.apple.com/us/app/Rutes/", "_blank")
                }
              />
            </div>
          </div>
        </div>
        <div className="right">
          <img
            src={headerIntro}
            className="header-intro"
            alt="headerSideImages"
          />
          <img src={mobileIntro} className="mobile-intro" alt="mobileIntro" />
        </div>
      </div>
    </div>
  );
}

export default HomeSection;
